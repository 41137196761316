import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, List, ListItem, ListItemText, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import background from '../src/bg1.png'; // Main background image

function CasePage({ title, cases }) {
    const theme = useTheme();
    return (
<Grid container justifyContent="center" alignItems="flex-start" sx={{
      minHeight: '100vh', // Ensure the container takes up at least the viewport height
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }}>   
    <Grid item xs={false} md={3} lg={3} sx={{backgroundColor: 'white'}}></Grid>
        <Grid item xs={12} md={6} lg={6} style={{ height: '100vh', backgroundColor: 'white'}}>
                <Box sx={{mx: 'auto', p: 3, minheight: '100vh', overflowY: 'auto' }}>
            <Typography variant="h4" gutterBottom>{title}</Typography>
            <List>
            {cases.map(c => (
                <ListItem button key={c.id} component={Link} to={`${c.id}`} sx={{borderBottom: '1px solid lightgrey'}}>
                <ListItemText primary={c.name} />
                </ListItem>
            ))}
            </List>
        </Box>
        </Grid>
        <Grid item xs={false} md={3} lg={3} sx={{backgroundColor: theme.palette.info.light}}></Grid>
    </Grid>
    );
};

export default CasePage;
