import React from 'react';
import { Typography, Box, Button, Grid, Card, CardContent, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import background from '../src/bg1.png'; // Main background image
import background2 from '../src/back33.gif'; // Card background gif
import logo from '../src/promlogo.png'; // Logo image
import pdf from '../src/XLRI_PM_Casebook_2024.pdf'; // PDF download link

function CaseHome() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container minHeight="100vh" justifyContent="center" alignItems="center" sx={{
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }}>
      <Grid item xs={11} md={8} lg={15} sx={{ textAlign: 'center'}}>
        <Box sx={{ mt: 0 }}>
          <Card variant="outlined" sx={{
            p: 3.5,
            backgroundImage: `url(${background2})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '20px', // Rounded corners for the card
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Adds shadow to the card for depth
            color: "#fff", // Text color for better readability
            marginBottom: '0rem' // Spacing between cards
          }}>
            <CardContent>
              <img src={logo} height={matches ? "120px" : "80px"} alt="Prometheus Logo" style={{ marginBottom: '20px' }} />
              <Typography variant={matches ? "h3" : "h4"} gutterBottom>Prometheus</Typography>
              <Typography variant="h5" color="#fff">Case and Material Repository</Typography>
            </CardContent>
          </Card>
        </Box>
          </Grid>
          <Grid item xs={11} md={9} lg={9} sx={{ textAlign: 'center'}}>

        <Box sx={{ opacity: 0.9 }}>
          <Card variant="outlined" sx={{
            p: 0,
            borderRadius: '20px', // Rounded corners for the card
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Adds shadow to the card for depth

          }}>
            <CardContent>
              <Typography variant="h4" gutterBottom>Case Types</Typography>
              <Typography variant="h6" color="text.secondary" mb={3}>Find cases based on case types</Typography>
              <Grid container spacing={2} justifyContent="center">
                {/* Button styles are adjusted to be more consistent and modern */}
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" component={Link} to="/product-design-improvement" fullWidth sx={{ py: 1.5, backgroundColor: theme.palette.primary.main }}>Prod. Design/Improvement</Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" component={Link} to="/rca" fullWidth sx={{ py: 1.5, backgroundColor: theme.palette.primary.main }}>Root Cause Analysis</Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" component={Link} to="/gtm-pricing" fullWidth sx={{ py: 1.5, backgroundColor: theme.palette.primary.main }}>GTM/Pricing</Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" component={Link} to="/metrics-guesstimates" fullWidth sx={{ py: 1.5, backgroundColor: theme.palette.primary.main }}>Metrics & Guesstimates</Button>
                </Grid>
                <Grid item xs={12}>
                  <Button href={pdf} variant="contained" target='_blank' fullWidth sx={{ py: 1.5, backgroundColor: theme.palette.secondary.main }}>PM Casebook 2024</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CaseHome;
