import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Card, CardContent, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LinkedIn, Instagram } from '@mui/icons-material'; // Import LinkedIn and Instagram icons
import background from '../src/bg1.png'; // Main background image
import background2 from '../src/back33.gif'; // Card background gif
import logo from '../src/promlogo.png'; // Logo image
import teamPhoto from '../src/team_photo.jpeg'; // Team photo
import ambassador from '../src/ambassador.png'; 
import battleofapps from '../src/Battle of the Apps.png'; 
import games from '../src/games.png'; 
import cxo from '../src/CXO Summit.png'; 
import envision from '../src/envision.png'; 
import mindmatrix from '../src/MindMatrix.png';
import prodigi from '../src/Prodigi.png';
import prodalytics from '../src/prodalytics.png';



function HomePage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  // Array of image URLs for the banner
  const bannerImages = [
    ambassador,
    battleofapps,
    games,
    cxo,
    envision,
    mindmatrix,
    prodalytics,
    prodigi


  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
    }, 3000); // Switch to the next image after 3 seconds

    return () => clearTimeout(timer);
  }, [currentImageIndex, bannerImages.length]);

  return (
    <Grid
      container
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid item xs={11} md={8} lg={15} sx={{ textAlign: 'center', marginBottom: '40px' }}>
        <Box>
          <Card
            variant="outlined"
            sx={{
              p: 3.5,
              backgroundImage: `url(${background2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '20px',
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              color: "#fff",
            }}
          >
            <CardContent>
              <img src={logo} height={matches ? "120px" : "80px"} alt="Prometheus Logo" style={{ marginBottom: '20px' }} />
              <Typography variant={matches ? "h3" : "h4"} gutterBottom>Prometheus</Typography>
              <Typography variant="h5" color="#fff">Product, Technology & UX</Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>

     

      <Grid item xs={11} md={9} lg={15} sx={{ textAlign: 'center', marginBottom: '20px' }}>
        <Box sx={{ opacity: 0.9 }}>
          <Card
            variant="outlined"
            sx={{
              p: 3,
              borderRadius: '20px',
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            }}
          >
            <CardContent>
              <Typography variant="body1" color="black">"Empowering students to navigate the world of Product Management, opening doors to limitless possibilities"</Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
 {/* Banner Section */}
 <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '20px' }}>
        <Box>
          <img src={bannerImages[currentImageIndex]} alt="Banner" style={{ maxWidth: '100%', borderRadius: '10px' }} />
        </Box>
      </Grid>

      <Grid item xs={11} md={9} lg={9} sx={{ textAlign: 'center' }}>
        <Box sx={{ opacity: 0.9 }}>
          <Card
            variant="outlined"
            sx={{
              p: 3,
              borderRadius: '20px',
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            }}
          >
            <CardContent>
              <Typography variant="h4" gutterBottom>Meet Our Team</Typography>
              <img src={teamPhoto} alt="Team" style={{ maxWidth: '100%', borderRadius: '10px', marginBottom: '20px' }} />
            </CardContent>
          </Card>
        </Box>
      </Grid>

      {/* Footer Section */}
      <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Box>
          <Typography variant="h6" gutterBottom color="#fff">Follow us on social media:</Typography>
          <Box>
            <a href="https://www.linkedin.com/company/prometheus-xlri/" target="_blank" rel="noopener noreferrer"><LinkedIn fontSize="large" sx={{ color: '#fff' }} /></a>
            <a href="https://www.instagram.com/prometheus_xlri/" target="_blank" rel="noopener noreferrer"><Instagram fontSize="large" sx={{ color: '#fff' }} /></a>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomePage;
