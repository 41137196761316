import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Hidden, IconButton, Menu, MenuItem, ButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu'
import texlogo from '../src/texlence_logo.png';
import promlogo from '../src/promlogo.png';
import pdf from '../src/XLRI_PM_Casebook_2024.pdf'

function Navbar() {

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElInterviewPrep, setAnchorElInterviewPrep] = useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInterviewPrepClick = (event) => {
    setAnchorElInterviewPrep(event.currentTarget);
  };

  const handleInterviewPrepClose = () => {
    setAnchorElInterviewPrep(null);
  };


  return (
    <AppBar position="sticky" elevation={2}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          
          <ButtonGroup color="inherit" component={Link} to="/">
              {/* <HomeIcon style={{color: "white", marginTop: "5px"}} fontSize='large'/> */}
              <span style={{marginTop: "5px"}}><img src={promlogo} width="80px" /></span>
          </ButtonGroup>

          <ButtonGroup component={Link} to="https://prometheus.xlri.ac.in/texlence/" target='_blank'>
            <span style={{marginLeft: "25px", marginTop: "8px"}}><img src={texlogo} width="100px" /></span>
          </ButtonGroup>
        </Typography>

        <Hidden mdDown>
          {/* <Button color="inherit" component={Link} to="/">Meet the team</Button> */}
          <Button color="inherit" component={Link} to="/" sx={{
            fontFamily: 'Arial, sans-serif',  // Change font to Arial
            fontWeight: 'bold',  // Make text bold
              '&:hover': {
                transform: 'scale(1.1)',
                transition: 'transform 0.3s ease-in-out',
              },
            }}>Home</Button>
          <Button color="inherit" component={Link} to="https://prometheus.xlri.ac.in/texlence/" target='_blank'sx={{
            fontFamily: 'Arial, sans-serif',  // Change font to Arial
            fontWeight: 'bold',  // Make text bold
              '&:hover': {
                transform: 'scale(1.1)',
                transition: 'transform 0.3s ease-in-out',
              },
            }}>Texlence</Button>
          <Button 
            color="inherit"
            aria-controls="interview-prep-menu"
            aria-haspopup="true"
            onMouseOver={handleInterviewPrepClick}
            sx={{
              color: '#FFc300',  // Set text to white for contrast
              fontFamily: 'Arial, sans-serif',  // Change font to Arial
              fontWeight: 'bold',  // Make text bold
              '&:hover': {
                transform: 'scale(1.1)',
                transition: 'transform 0.3s ease-in-out',
              },
            }}
          >
            Interview Prep
          </Button>
          <Menu
            id="interview-prep-menu"
            anchorEl={anchorElInterviewPrep}
            keepMounted
            open={Boolean(anchorElInterviewPrep)}
            onClose={handleInterviewPrepClose}
            MenuListProps={{ onMouseLeave: handleInterviewPrepClose }}
          >
            <MenuItem onClick={handleClose} component={Link} to="/CaseHome">Preparation Repository</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/product-design-improvement">Product Design/Improvement</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/rca">RCA</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/gtm-pricing">GTM/Pricing</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/metrics-guesstimates">Metrics/Guesstimates</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to={pdf} target='_blank'>Casebook</MenuItem>
          </Menu>
          <Button color="inherit" component={Link} to={pdf} target='_blank'sx={{
            fontFamily: 'Arial, sans-serif',  // Change font to Arial
            fontWeight: 'bold',  // Make text bold
              '&:hover': {
                transform: 'scale(1.1)',
                transition: 'transform 0.3s ease-in-out',
              },
            }}>Casebook</Button>
        </Hidden>

        <Hidden mdUp>
          <IconButton color="inherit" aria-label="menu" onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} component={Link} to="/product-design-improvement">Product Design/Improvement</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/rca">RCA</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/gtm-pricing">GTM/Pricing</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/metrics-guesstimates">Metrics/Guesstimates</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to={pdf} target='_blank'>Casebook</MenuItem>
          </Menu>
        </Hidden>

      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
