import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function CaseDetails({ cases }) {
  const { caseType, caseId } = useParams();
  const caseDetail = cases[caseType].find(c => c.id === caseId);
  const theme = useTheme();
  return (
    <Grid container height="100vh">
      <Grid item xs={false} md={3} lg={3} sx={{backgroundColor: theme.palette.info.light}}></Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Box sx={{mx: 'auto', p: 3}}>
          <Typography variant="h4" gutterBottom>{caseDetail.name}</Typography>
          <div style={{textWrap: 'wrap', whiteSpace: 'pre-line'}}><Typography variant="body1" dangerouslySetInnerHTML={{ __html: caseDetail.transcript }} /></div>
        </Box>
      </Grid>
      <Grid item xs={false} md={3} lg={3} sx={{backgroundColor: theme.palette.info.light}}></Grid>
    </Grid>
  );
};

export default CaseDetails;
